import React from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Link from 'next/link';
import { signIn } from 'next-auth/react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import UrlMapper from '@/config/url-mapper';
import { Button, Textfield, Typography } from '@/components/atoms';
import { UnexpectedError } from '@/components/molecules';
import { ButtonContainer, CustomForm, TextWrapper } from './LoginForm.styles';

type LoginFormType = {
    email: string;
    password: string;
};

const LoginForm: React.FC = () => {
    const { t, i18n } = useTranslation(); // Translations
    const router = useRouter(); // Router
    const { executeRecaptcha } = useGoogleReCaptcha(); // Recaptcha
    const [sending, setSending] = React.useState<boolean>(false);
    const [globalError, setGlobalError] = React.useState<boolean>(false);

    // Form initial values
    const initialValues = {
        email: '',
        password: '',
    };

    // Form validation schema
    const validationSchema = Yup.object({
        email: Yup.string().email(t('auth:login-form.invalid.email')).required(t('auth:login-form.required.email')),
        password: Yup.string().required(t('auth:login-form.required.password')),
    });

    const handleOnSubmit = async (
        values: LoginFormType,
        {
            setFieldError,
        }: {
            setFieldError: (field: string, message: string | undefined) => void;
        }
    ) => {
        setGlobalError(false);
        setSending(true);

        if (executeRecaptcha) {
            const recaptchaToken = await executeRecaptcha('LOGIN');

            const response = await signIn('login-provider', {
                ...values,
                recaptchaToken,
                language: i18n.language,
                redirect: false,
                callbackUrl: process.env.NEXT_PUBLIC_DOMAIN_URL,
            });

            if (response?.error) {
                setSending(false);

                if (response?.status === 401) {
                    setFieldError('email', t('auth:login-form.invalid.credentials'));
                } else {
                    setGlobalError(true);
                }
            }

            if (response?.url) await router.push(response.url);
        }
    };

    return (
        <>
            <Typography as="h1" variant="h6" align="center">
                {t('auth:login-form.section.title')}
            </Typography>

            <TextWrapper>
                {globalError && <UnexpectedError />}

                {!globalError && (
                    <Typography variant="body2" align="center">
                        {t('auth:login-form.section.description')}
                    </Typography>
                )}
            </TextWrapper>

            <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleOnSubmit}>
                {({ errors, values, touched, handleChange, handleBlur }) => {
                    return (
                        <CustomForm>
                            <Textfield
                                id="email"
                                type="email"
                                label={t('auth:login-form.label.email')}
                                placeholder={t('auth:login-form.placeholder.email')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values?.email}
                                errors={touched.email && errors.email}
                                success={(touched.email || values?.email !== '') && !errors.email}
                            />
                            <Textfield
                                id="password"
                                type="password"
                                revealPasswordIcon
                                label={t('auth:login-form.label.password')}
                                placeholder={t('auth:login-form.placeholder.password')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values?.password}
                                errors={touched.password && errors.password}
                                success={(touched.password || values?.password !== '') && !errors.password}
                            />
                            <Link href={UrlMapper.forgot_password}>
                                <Typography as="span" variant="body2" weight={600} align="right" mr="20px">
                                    {t('auth:login-form.label.forgot-password')}
                                </Typography>
                            </Link>
                            <ButtonContainer>
                                <Button content={t('auth:button.signup')} type="button" variant="outlined" onClick={() => void router.push(UrlMapper.signup)} disabled={sending} />
                                <Button content={t('auth:button.login')} type="submit" variant="filled" isSubmitting={sending} disabled={sending} />
                            </ButtonContainer>
                        </CustomForm>
                    );
                }}
            </Formik>
        </>
    );
};

export default LoginForm;
