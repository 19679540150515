import React from 'react';
import { NextSeo } from 'next-seo';
import { GetServerSideProps } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { withRecaptcha } from '@/lib/recaptcha';
import { redirectToUnderConstructionPage } from '@/utils/rewrites';
import LoginForm from '@/forms/login-form';
import { AuthLayout, Layout } from '@/components/templates';
import { NextPageWithLayout } from '@/types';

const PageContent = withRecaptcha(() => {
    return (
        <>
            <LoginForm />
        </>
    );
});

const LoginPage: NextPageWithLayout = () => {
    const { t } = useTranslation(); // Translations

    return (
        <>
            <NextSeo title={t('seo:login.title')} titleTemplate="%s" />

            <PageContent />
        </>
    );
};

LoginPage.getLayout = (page: React.ReactElement) => {
    return (
        <Layout>
            <AuthLayout activeSide="left" variant="form">
                {page}
            </AuthLayout>
        </Layout>
    );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
    const rules = redirectToUnderConstructionPage();

    const translations = await serverSideTranslations(context?.locale as string, ['common', 'seo', 'auth']);

    return {
        ...rules,
        props: {
            ...translations,
        },
    };
};

export default LoginPage;
