import styled from 'styled-components';
import { Form } from 'formik';

export const Wrapper = styled.main`
    display: flex;
    height: 100vh;
    position: static;
`;

export const TextWrapper = styled.div`
    height: 40px;
    display: inline;
`;

export const CustomForm = styled(Form)`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 32px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 35px;
    gap: 5px;

    button {
        flex-basis: 100%;
    }

    ${({ theme }) => theme.media.md`
        gap: 20px;

        button {
            flex-basis: 50%;
        }
    `}
`;
